import React, { useEffect } from "react";

import Chat from "./components/Chat/Chat";
import Join from "./components/Join/Join";
import ChattingList from "./components/ChattingList/ChattingList";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./global.css";
const App = () => {
  useEffect(() => {
    document.oncontextmenu = function () {
      return false;
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Join />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/chattingList" element={<ChattingList />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
