export default function selectLanguage() {
  const language =
    navigator.language || navigator.userLanguage || navigator.systemLanguage;
  const lowerCaseLanguage = language.toLowerCase();

  let userLang;

  if (lowerCaseLanguage.includes("ko")) {
    userLang = "ko";
  } else if (lowerCaseLanguage.includes("en")) {
    userLang = "en";
  } else if (lowerCaseLanguage.includes("ja")) {
    userLang = "ja";
  } else if (lowerCaseLanguage.includes("zh")) {
    userLang = "zh";
  } else if (lowerCaseLanguage.includes("th")) {
    userLang = "th";
  } else {
    userLang = "en";
  }
  return userLang;
}
