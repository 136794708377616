import React, { useEffect, useState } from "react";
import "./ChattingList.css";
import { Link, useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { getLocalStorageInfo } from "../../utils/getInfo";
import getLocation from "../../utils/getLocation";
const ENDPOINT = process.env.REACT_APP_END_POINT;
const ADMIN_NAME = ["이종선", "이종훈", "이종선1"];
const TOTAL_MEMBER = [
  "이종선",
  "강명수",
  "이종훈",
  "이유나",
  "박민호",
  "이선재",
  "정성필",
  "이승화",
  "장수빈",
  "홍주완",
  "황선순",
];
export default function ChattingList() {
  let socket;
  socket = io(ENDPOINT);
  const navigate = useNavigate();
  const [roomList, setRoomList] = useState([]);
  const [userLogin, setIsUserLogin] = useState(false);
  const [adminName, setAdminName] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [mobileId, setMobileId] = useState("");
  const [mobilePassword, setMobilePassword] = useState("");
  const messageList = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_END_POINT}activeRooms`
    );
    const data = await response.json();
    const result = data.filter((v) => v.chattingHistory !== undefined);
    const resultActive = result.filter((v) => v.isChattingActive);
    const resultDeactive = result.filter((v) => !v.isChattingActive);
    setRoomList([...resultActive, ...resultDeactive]);
  };
  useEffect(() => {
    const handleMessage = (event) => {
      // 보안을 위해 메시지 출처를 확인
      setIsUserLogin(true);
      const date = new Date();

      localStorage.setItem(
        "adminInfo",
        JSON.stringify({
          userLogin: true,
          loginTime: date,
          adminName: event.data,
        })
      );
      messageList();
      // 여기서 받은 메시지를 처리합니다
    };

    window.addEventListener("message", handleMessage);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    const isUserLogin = getLocalStorageInfo("adminInfo");
    if (isUserLogin === null) {
      setIsUserLogin(false);
    } else {
      setIsUserLogin(true);

      const date = new Date();

      localStorage.setItem(
        "adminInfo",
        JSON.stringify({
          userLogin: true,
          loginTime: date,
          adminName: isUserLogin.adminName,
        })
      );
      setAdminName(isUserLogin.adminName);
      messageList();
    }
  }, []);

  useEffect(() => {
    socket.on("updateNewMessage", () => {
      messageList();
    });
  }, []);

  const changeName = (name) => {
    if (name === "이종선" || name === "이종선1") {
      return "대표님";
    }
    if (name === "고석수") {
      return "CPO님";
    }
    if (name === "이종훈") {
      return "본부장님";
    }
    if (name === "강명수") {
      return "리드매니저님";
    }
    return "매니저님";
  };

  const changeManager = async (item, newManager) => {
    fetch(`${process.env.REACT_APP_END_POINT}changeManager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        room: item.roomId,
        newManager,
      }),
    }).then(() => {
      alert(
        "담당자가 " + newManager + changeName(newManager) + "로 지정되었습니다."
      );
      window.location.reload();
    });
  };

  return userLogin ? (
    <div className="paddingDiv">
      <div className="chatjoinOuterContainer">
        <div className="joinInnerContainer">
          <div className="heading">
            채팅 리스트
            {userLogin && (
              <button
                style={{
                  border: "none",
                  color: "white",
                  borderRadius: "10px",
                  backgroundColor: "#FFC028",
                  width: "100px",
                  height: 30,
                  position: "absolute",
                  right: "5%",
                  top: "2%",
                }}
                onClick={() => {
                  window.localStorage.removeItem("adminInfo");
                  setIsUserLogin(false);
                }}
              >
                로그아웃
              </button>
            )}
          </div>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "1px solid #ddd",
              marginBottom: 30,
            }}
          >
            <thead>
              <tr
                style={{
                  color: "white",
                  border: "1px solid #ddd",
                }}
              >
                {[
                  "언어",
                  "지점",
                  "이름",
                  "메시지",
                  "메시지 수신시간",
                  "메시지 발송 시간",
                  "채팅 종료 시간",
                  "담당자",
                  "읽지 않은 메시지",
                  "채팅 종료 자",
                ].map((v) => {
                  return <th className="tdStyle">{v}</th>;
                })}
                {ADMIN_NAME.includes(adminName) ? (
                  <th className="tdStyle">담당자 교체</th>
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            <tbody>
              {roomList.map((item) => {
                return (
                  <tr
                    style={{
                      color: item.isChattingActive ? "white" : "#d2d2d2",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/chat?name=admin&room=${item.roomId}`, {
                        state: { data: item.manager },
                      });
                    }}
                  >
                    <td className="tdStyle">{item.userLanguage}</td>
                    <td className="tdStyle">{getLocation(item.location)}</td>
                    <td className="tdStyle">{item.userName}</td>
                    <td className="tdStyle">{item.chattingHistory}</td>
                    <td className="tdStyle">
                      {item.userLastSend.length === 0
                        ? ""
                        : item.userLastSend[item.userLastSend.length - 1]
                            .timestamp}
                    </td>

                    <td className="tdStyle">
                      {item.adminLastSend.length === 0
                        ? ""
                        : item.adminLastSend[item.adminLastSend.length - 1]
                            .timestamp}
                    </td>
                    <td className="tdStyle">{item.chattingCloseTime}</td>
                    <td className="tdStyle">
                      {item.manager === ""
                        ? "미배정"
                        : item.manager + " " + changeName(item.manager)}
                    </td>
                    <td
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="tdStyle"
                    >
                      {item.noReadMessage === 0 ? (
                        <span>없음</span>
                      ) : (
                        <span
                          style={{
                            textAlign: "center",
                            backgroundColor: "red",
                            padding: "2px 5px",
                            borderRadius: "22px",
                          }}
                        >
                          {item.noReadMessage}
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="tdStyle"
                    >
                      {item.closeActor === "admin"
                        ? "관리자"
                        : item.closeActor === "user"
                        ? "사용자"
                        : ""}
                    </td>
                    {ADMIN_NAME.includes(adminName) && item.isChattingActive ? (
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="tdStyle"
                      >
                        <select
                          name="languages"
                          id="lang"
                          defaultValue={
                            item.manager === "" ? "미배정" : item.manager
                          }
                          onChange={(e) => {
                            changeManager(item, e.target.value);
                          }}
                        >
                          <option value="">미배정</option>
                          {TOTAL_MEMBER.map((v) => {
                            if (v === "이종선") {
                              return <option value={v}>{v} 대표님</option>;
                            }

                            if (v === "이종훈") {
                              return <option value={v}>{v} 본부장님</option>;
                            }
                            if (v === "강명수") {
                              return (
                                <option value={v}>{v} 리드매니저님</option>
                              );
                            }
                            return <option value={v}>{v} 매니저</option>;
                          })}
                        </select>
                      </td>
                    ) : (
                      <></>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <div className="paddingDiv">
      <div className="managerLogin">
        <h1 className="heading">관리자 로그인</h1>
        <div>
          {isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 30,
                justifyItems: "flex-start",
                fontSize: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                id{" "}
                <input
                  onChange={(e) => {
                    setMobileId(e.target.value);
                  }}
                  value={mobileId}
                  style={{ border: "none", borderRadius: 5, height: 30 }}
                ></input>
              </div>
              <div>
                password{" "}
                <input
                  type="password"
                  onChange={(e) => {
                    setMobilePassword(e.target.value);
                  }}
                  value={mobilePassword}
                  style={{ border: "none", borderRadius: 5, height: 30 }}
                ></input>
              </div>
              <button
                style={{
                  border: "none",
                  padding: 15,
                  borderRadius: 10,
                  backgroundColor: "#FFC028",
                  color: "white",
                  fontSize: 25,
                  fontWeight: 700,
                }}
                onClick={() => {
                  fetch(`${process.env.REACT_APP_END_POINT}mobile_login`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      id: mobileId,
                      password: mobilePassword,
                    }),
                  }).then(async (res) => {
                    const data = await res.json();
                    if (data !== "FAIL") {
                      setIsUserLogin(true);
                      const date = new Date();

                      localStorage.setItem(
                        "adminInfo",
                        JSON.stringify({
                          userLogin: true,
                          loginTime: date,
                          adminName: data,
                        })
                      );
                      messageList();
                    } else {
                      alert("아이디 및 비밀번호를 확인해주세요");
                    }
                  });
                }}
              >
                로그인
              </button>
            </div>
          ) : (
            <button
              className="googleLogin"
              onClick={() => {
                if (window.innerWidth < 500) {
                  setIsMobile(true);
                } else {
                  const openWindow = window.open("", "_blank");
                  openWindow.location =
                    "https://dev.moneyplex.kr/_plugin/social/popup2.php?provider=google";
                }
              }}
            >
              구글 로그인
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
