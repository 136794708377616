import React from "react";

export default function ModalComponent({
  leftHandler,
  rightHandler,
  mainText,
  leftText,
  rightText,
}) {
  return (
    <div className="modal">
      <div
        style={{
          fontSize: "20px",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        {mainText}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          onClick={leftHandler}
          style={{
            width: 100,
            marginRight: 20,
            padding: "10px",
            color: "#FFC028",
            borderRadius: "5px",
            border: "1px solid #FFC028",
          }}
        >
          {leftText}
        </div>
        <div
          onClick={rightHandler}
          style={{
            backgroundColor: "#FFC028",
            width: 100,

            padding: "10px",
            color: "white",
            borderRadius: "5px",
          }}
        >
          {rightText}
        </div>
      </div>
    </div>
  );
}
