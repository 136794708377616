export default function getLocation(location) {
  let newLocation;
  if (location?.includes("seoul-station")) {
    if (location === "seoul-station-2") {
      newLocation = "seoul-station-B";
    } else {
      newLocation = "seoul-station-A";
    }
  } else {
    newLocation = location;
  }
  return newLocation;
}
