import React, { useEffect, useState } from "react";

import onlineIcon from "../../icons/onlineIcon.png";
import closeIcon from "../../icons/closeIcon.png";

import "./InfoBar.css";
import { useLocation } from "react-router-dom";
import { getLocalStorageInfo } from "../../utils/getInfo";
import getText from "../../utils/getText";
const InfoBar = ({
  room,
  userLanguage,
  userName,
  setUserName,
  setExitModal,
  nickNameModal,
  adminName,
  setAdminName,
  isMobile,
  onClickHandler,
  chattingInfo,
  setExitModalText,
}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [userLocation, setUserLocation] = useState("");
  const location = useLocation();
  const changeUserLocation = (location) => {
    if (location.includes("seoul-station")) {
      if (location === "seoul-station-2") {
        setUserLocation("seoul-station-B");
      } else {
        setUserLocation("seoul-station-A");
      }
    } else {
      setUserLocation(location);
    }
  };
  const getAdminName = async (roomId) => {
    const response = await fetch(
      `${process.env.REACT_APP_END_POINT}activeRoom/${roomId}`
    );
    const reciveData = await response.json();
    const data = reciveData[0];
    if (data.manager === "") {
      setAdminName("미배정");
    } else {
      setAdminName(data.manager);
    }
    changeUserLocation(data.location);
    setUserName(data.userName);
    setIsAdmin(true);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const roomId = searchParams.get("room");
    if (roomId === null) {
      setIsAdmin(false);
      const userInfo = getLocalStorageInfo("userInfo");
      setUserName(userInfo?.userName);
      changeUserLocation(searchParams.get("location"));
    } else {
      getAdminName(roomId);
    }
  }, [userName]);

  return (
    <div className="infoBar">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "start",
          rowGap: "5px",
        }}
        className="leftInnerContainer"
      >
        {isAdmin && isMobile && (
          <div
            onClick={onClickHandler}
            style={{
              color: "white",
            }}
          >
            &lt; 채팅목록으로 이동
          </div>
        )}
        <div>language : {userLanguage}</div>
        <div> kiosk location : {userLocation}</div>
        {isAdmin ? (
          <>
            <div>{userName !== undefined ? `userName : ${userName}` : ""}</div>
            <div>
              manager :{" "}
              {adminName === "" || adminName === null ? "미배정" : adminName}
            </div>
          </>
        ) : (
          <div>{userName !== undefined ? `userName : ${userName}` : ""}</div>
        )}
      </div>
      {chattingInfo?.isChattingActive && (
        <div
          onClick={() => {
            setExitModal(true);
          }}
          style={{
            padding: 15,
          }}
          className="rightInnerContainer"
        >
          {!nickNameModal && <img src={closeIcon} alt="close icon" />}
        </div>
      )}
    </div>
  );
};

export default InfoBar;
