const string = {
  ko: {
    writeName: "이름을 적어주세요",
    enter: "입장",
    PressButtonSay: "버튼을 누른 후 말씀해주세요",
    typeMessage: "메시지를 입력해 주세요...",
    releaseButton: "말씀이 끝난 후 버튼을 놓아주세요",
    pleaseSpeaking: "듣는 중",
    exitChatting: "채팅을 종료하시겠습니까?",
    cancel: "취소",
    exit: "종료",
    exitMessage: "채팅이 종료되었습니다.\n감사합니다.",
    send: "전송",
  },
  zh: {
    writeName: "请写下您的名字",
    enter: "回车",
    PressButtonSay: "按下按钮后请讲话",
    typeMessage: "请输入信息...",
    releaseButton: "说完后请松开按钮",
    pleaseSpeaking: "正在听",
    exitChatting: "您要结束聊天吗？",
    cancel: "取消",
    exit: "结束",
    exitMessage: "聊天结束了。\n谢谢.",
    send: "发送",
  },
  en: {
    writeName: "Please write down your name",
    enter: "enter",
    PressButtonSay: "Press button and say",
    typeMessage: "Type a message....",
    releaseButton: "Release the button after you finish speaking",
    pleaseSpeaking: "Please Speaking",
    exitChatting: "Do you want to end the chat?",
    cancel: "Cancel",
    exit: "Exit",
    exitMessage: "The chatting has ended.\n Thank you.",
    send: "SEND",
  },
  ja: {
    writeName: "名前を書いてください ",
    enter: "エンター",
    PressButtonSay: "ボタンを押してから話してください",
    typeMessage: "メッセージを入力してください...",
    releaseButton: "話し終わったらボタンを離してください",
    pleaseSpeaking: "聞いています",
    exitChatting: "チャットを終了しますか?",
    cancel: "キャンセル",
    exit: "終了",
    exitMessage: "チャットが終了しました。\nありがとうございます.",
    send: "送信",
  },
  th: {
    writeName: "กรุณาเขียนชื่อของคุณ",
    enter: "กดปุ่มเข้า",
    PressButtonSay: "กรุณาพูดหลังจากกดปุ่ม",
    typeMessage: "กรุณาใส่ข้อความ...",
    releaseButton: "ปล่อยปุ่มหลังจากพูดเสร็จ",
    pleaseSpeaking: "กำลังฟังอยู่",
    exitChatting: "คุณต้องการปิดการสนทนาหรือไม่?",
    cancel: "ยกเลิก",
    exit: "ปิด",
    exitMessage: "การสนทนาสิ้นสุดแล้ว \n ขอบคุณ.",
    send: "ส่ง",
  },
};

export default string;
