import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";

import Messages from "../Messages/Messages";
import InfoBar from "../InfoBar/InfoBar";
import Input from "../Input/Input";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";

import "./Chat.css";
import FirstMic from "../../icons/FirstMic.png";
import FirstClickMic from "../../icons/FirstClickMic.png";
import logo from "../../icons/Logo.png";
import selectLanguage from "../../utils/getUserLanguage";
import getText from "../../utils/getText";
import { getLocalStorageInfo } from "../../utils/getInfo";
import ModalComponent from "./ModalCompoent";
import getLocation from "../../utils/getLocation";
const ENDPOINT = process.env.REACT_APP_END_POINT;

let socket;

const Chat = () => {
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [userLanguage, setUserLanguage] = useState();
  const [isLisning, setIsLisning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nickNameModal, setNickNameModal] = useState(false);
  const [userInputName, setUserInputName] = useState("");
  const [isFirstMic, setIsFirstMic] = useState(true);
  const [isLisningFirst, setIsLisningFirst] = useState(false);
  const [userName, setUserName] = useState("");
  const [exitModal, setExitModal] = useState(false);
  const [clickMic, setClickMic] = useState(false);
  const [userExit, setUserExit] = useState(false);
  const [isMicLisning, setIsMicLisning] = useState(false);
  const [sendUserText, setSendUserText] = useState(false);
  const scrollBottomRef = useRef();
  const [adminName, setAdminName] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [chattingInfo, setChattingInfo] = useState();
  const searchParams = new URLSearchParams(location.search);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [textDeleteModal, setTextDeleteModal] = useState(false);
  const [textDeleteUuid, setTextDeleteUuid] = useState("");
  const fetchChatHistory = async (room) => {
    const response = await fetch(`${ENDPOINT}chatHistory/${room}`);
    const data = await response.json();
    return data;
  };

  const readMessage = async (room) => {
    await fetch(`${ENDPOINT}readMessage/${room}`);
  };

  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  useEffect(() => {
    if (localStorage.getItem("userInfo") !== null) {
      SpeechRecognition.startListening();
      setTimeout(() => {
        SpeechRecognition.stopListening();
      }, 100);
    }
  }, []);
  const previousUser = async (userInfo) => {
    const user = userInfo;
    const response = await fetch(
      `${process.env.REACT_APP_END_POINT}activeRoom/${user.uuid}`
    );

    const data = await response.json();
    console.log(data);
    console.log(chattingInfo, 123);
    setChattingInfo(data[0]);
    if (data.length !== 0 && data[0].isChattingActive) {
      fetchChatHistory(user.uuid).then((res) => {
        setMessages(res);
      });
      socket.emit(
        "join",
        { name: user.userName, room: user.uuid, location },
        (error) => {}
      );
    } else {
      localStorage.removeItem("userInfo");
      window.location.reload();
    }
  };

  //사용자가 제일 처음 들어왔을 때 소켓 연결
  useEffect(() => {
    if (window.innerWidth < 500) {
      setIsMobile(true);
    }

    socket = io(ENDPOINT);
    const name = searchParams.get("name");
    const room = searchParams.get("room");
    //관리자일 경우

    if (name === "admin") {
      readMessage(room); // 관리자가 읽었는지 여부 추가
      setRoom(room);
      setName(name);
      fetch(`${process.env.REACT_APP_END_POINT}activeRoom/${room}`).then(
        async (res) => {
          const data = await res.json();
          setChattingInfo(data[0]);
        }
      );
      fetchChatHistory(room).then((res) => {
        setMessages(res);
        setUserLanguage(res[res.length - 1]?.userLanguage);
      });
      socket.emit("join", { name: "admin", room: room }, (error) => {});

      return;
    }

    //사용자일 경우
    const userInfo = getLocalStorageInfo("userInfo");
    if (userInfo === null) {
      setNickNameModal(true);
    } else {
      previousUser(userInfo);
    }

    let userLang = selectLanguage();

    setUserLanguage(userLang);
  }, []);

  useEffect(() => {
    socket.on("deleteMessage", (message) => {
      setMessages(message);
      setTextDeleteModal(false);
    });
    socket.on("managerAssignment", (adminName) => {
      const adminInfo = getLocalStorageInfo("adminInfo");

      if (adminName.split(" ")[1] === adminInfo.adminName) {
        setAdminName(adminInfo.adminName);
      } else {
        setAdminName(adminName.split(" ")[1]);
      }
    });
    socket.on("message", (message) => {
      console.log(message, 123);
      setMessages((messages) => [...messages, ...message]);
      setIsLoading(false);
    });
    socket.on("exitChatting", () => {
      setUserExit(true);
      setTimeout(() => {
        navigate("/chattinglist");
      }, 5000);
    });
    socket.on("roomData", ({ users }) => {});

    socket.on("sendSuccess", () => {
      setIsLoading(false);
      setMessage("");
    });
  }, []);

  const sendMessage = async () => {
    const room = searchParams.get("room");
    const getUserInfo = getLocalStorageInfo("userInfo");
    const getAdminInfo = getLocalStorageInfo("adminInfo");

    if (transcript === "") return;
    setIsLoading(true);

    if (transcript) {
      const newUuid = uuidv4();

      socket.emit(
        "sendMessage",
        {
          roomNumber: room !== null ? room : getUserInfo.uuid,
          userName:
            room !== null
              ? "admin " + getAdminInfo.adminName
              : getUserInfo.userName,
          origin: transcript,
          userLanguage,
          uuid: newUuid,
        },
        () => {}
      );
      return;
    }

    resetTranscript();
  };

  const sendMessageWithType = async () => {
    const room = searchParams.get("room");
    const getUserInfo = getLocalStorageInfo("userInfo");
    const getAdminInfo = getLocalStorageInfo("adminInfo");

    setIsLoading(true);

    if (message) {
      const newUuid = uuidv4();

      socket.emit(
        "sendMessage",
        {
          roomNumber: room !== null ? room : getUserInfo.uuid,
          userName:
            room !== null
              ? "admin " + getAdminInfo.adminName
              : getUserInfo.userName,
          origin: message,
          userLanguage,
          uuid: newUuid,
        },
        () => {}
      );
    }
  };

  const startListening = (e) => {
    setIsLisning(true);
    SpeechRecognition.startListening({ continuous: true });
  };
  const startListeningFirst = (e) => {
    SpeechRecognition.startListening({ continuous: true });
  };

  useEffect(() => {
    if (sendUserText) {
      sendMessage();
      setSendUserText(false);
    }
  }, [sendUserText]);

  useEffect(() => {
    const handleMouseDown = () => {
      if (isLisningFirst) {
        startListeningFirst();
      }
    };

    const handleMouseUp = () => {
      if (isLisningFirst) {
        setTimeout(() => {
          setSendUserText(true);
          SpeechRecognition.abortListening();
          resetTranscript();
        }, 1000);

        setIsLisningFirst(false);
        setIsFirstMic(false);
        setClickMic(false);
        setIsMicLisning(false);
      }
    };

    window.addEventListener("pointerdown", handleMouseDown);
    window.addEventListener("pointerup", handleMouseUp);

    return () => {
      window.removeEventListener("pointerdown", handleMouseDown);
      window.removeEventListener("pointerup", handleMouseUp);
    };
  }, [isLisningFirst]);

  useEffect(() => {
    if (isLisningFirst || isMicLisning) {
      startListeningFirst();
      return;
    }
    if (clickMic) {
      setIsLisningFirst(false);
      setIsFirstMic(false);
      setClickMic(false);
      setIsMicLisning(false);
    }
  }, [isLisningFirst, clickMic, isMicLisning]);

  const speechStart = () => {
    SpeechRecognition.startListening();
    setTimeout(() => {
      SpeechRecognition.stopListening();
    }, 100);
  };

  if (userExit) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "pre-line",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: 25,
          }}
        >
          {getText(userLanguage, room, "exitMessage")}
        </div>
      </div>
    );
  }
  const gotoChattingList = () => {
    navigate("/chattinglist");
  };

  const exitLeftHandler = () => {
    setExitModal(false);
  };
  const exitRightHandler = () => {
    const room = searchParams.get("room");
    const userInfo = getLocalStorageInfo("userInfo");

    if (room !== null) {
      fetch(`${ENDPOINT}exitChatting/${room}/admin `);
    } else {
      fetch(`${ENDPOINT}exitChatting/${userInfo.uuid}/user`);
    }

    setTimeout(() => {
      if (room === null) {
        localStorage.removeItem("userInfo");
        setUserExit(true);
        setChattingInfo();
      } else {
        setExitModal(false);
        navigate("/chattinglist");
      }
    }, 500);
  };
  return (
    <div className="outerContainer">
      {name === "admin" && !isMobile && (
        <div
          onClick={gotoChattingList}
          style={{
            position: "absolute",
            top: 30,
            left: 20,
            width: 200,
            backgroundColor: "#FFC028",
            padding: "10px 10px",
            textAlign: "center",
            borderRadius: 10,
            color: "white",
          }}
        >
          채팅목록으로 이동
        </div>
      )}
      {
        <div className="container">
          <InfoBar
            userLanguage={userLanguage}
            room={room}
            userName={userName}
            setUserName={setUserName}
            setExitModal={setExitModal}
            nickNameModal={nickNameModal}
            adminName={adminName}
            setAdminName={setAdminName}
            isMobile={isMobile}
            onClickHandler={gotoChattingList}
            chattingInfo={chattingInfo}
          />
          <Messages
            messages={messages}
            name={name}
            userLanguage={userLanguage}
            room={room}
            scrollBottomRef={scrollBottomRef}
            adminName={adminName}
            setTextDeleteModal={setTextDeleteModal}
            setTextDeleteUuid={setTextDeleteUuid}
          />
          {nickNameModal ? (
            <> </>
          ) : (
            <>
              {isFirstMic && searchParams.get("room") === null ? (
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!isLisningFirst && (
                    <>
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: 20,
                          color: "#000000",
                          marginBottom: 15,
                        }}
                      >
                        {getText(userLanguage, room, "PressButtonSay")}
                      </div>
                      <img
                        style={{
                          marginBottom: 50,
                        }}
                        width={75}
                        height={75}
                        src={FirstMic}
                        onPointerDown={(e) => {
                          e.preventDefault();
                          setClickMic(true);
                          setIsLisningFirst(true);
                        }}
                      ></img>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <Input
                    startListening={startListening}
                    isLoading={isLoading}
                    message={message}
                    setMessage={setMessage}
                    sendMessage={sendMessage}
                    setIsLisning={setIsLisning}
                    isLisning={isLisning}
                    setIsFirstMic={setIsFirstMic}
                    setIsLisningFirst={setIsLisningFirst}
                    setIsMicLisning={setIsMicLisning}
                    setClickMic={setClickMic}
                    isMicLisning={isMicLisning}
                    setSendUserText={setSendUserText}
                    sendUserText={sendUserText}
                    resetTranscript={resetTranscript}
                    sendMessageWithType={sendMessageWithType}
                    adminName={adminName}
                    userLanguage={userLanguage}
                    chattingInfo={chattingInfo}
                  />
                </>
              )}
            </>
          )}
        </div>
      }
      {(isLisning || exitModal || textDeleteModal) && (
        <div className="overlay"></div>
      )}

      {isLisning && (
        <div className="modal">
          <div
            style={{
              fontSize: "20px",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            <div className="dot-container">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
          <div
            onClick={() => {
              SpeechRecognition.stopListening();
              setMessage(transcript);
              setIsLisning(false);
            }}
            style={{
              backgroundColor: "#FFC028",
              width: "100%",
              padding: "10px",
              color: "white",
              borderRadius: "5px",
            }}
          >
            Stop
          </div>
        </div>
      )}
      {textDeleteModal && (
        <ModalComponent
          leftHandler={() => {
            setTextDeleteModal(false);
          }}
          rightHandler={() => {
            fetch(
              `${process.env.REACT_APP_END_POINT}deleteText/${room}/${textDeleteUuid}`
            ).then(async (res) => {
              // const data = await res.json();
              // console.log(data);
              // setMessages(data);
              setTextDeleteModal(false);
            });
          }}
          mainText={"메시지를 삭제하시겠습니까?"}
          leftText={"취소"}
          rightText={"확인"}
        />
      )}
      {exitModal && (
        <ModalComponent
          leftHandler={exitLeftHandler}
          rightHandler={exitRightHandler}
          mainText={getText(userLanguage, room, "exitChatting")}
          leftText={getText(userLanguage, room, "cancel")}
          rightText={getText(userLanguage, room, "exit")}
        />
      )}

      {isLisningFirst && !isMicLisning && (
        <div className="overlayFirst">
          <div
            className="overlayButton"
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              alignItems: "center",
              justifyItems: "flex-start",
              bottom: 0,
            }}
          >
            <img
              style={{
                marginBottom: 50,
                bottom: 0,
              }}
              width={75}
              height={75}
              src={FirstClickMic}
              onMouseLeave={() => {
                SpeechRecognition.stopListening();
                setMessage(transcript);
                setIsLisningFirst(false);
                setIsFirstMic(false);
                setIsMicLisning(false);
              }}
            ></img>
            <div
              style={{
                bottom: 0,
                zIndex: 10,
                fontWeight: 500,
                fontSize: 20,
                color: "#FFFFFF",
                marginBottom: 15,
              }}
            >
              {getText(userLanguage, room, "releaseButton")}
            </div>
          </div>
        </div>
      )}

      {isMicLisning && (
        <div className="overlayButtonFirst">
          <div
            className="overlayMicButton"
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontSize: 30,
                position: "relative",
                color: "white",
                bottom: 50,
              }}
            >
              {getText(userLanguage, room, "pleaseSpeaking")}
            </div>
          </div>
        </div>
      )}

      {(isLisningFirst || isMicLisning) && (
        <>
          <div className="firstmodal">
            <div className="dot-container">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        </>
      )}

      {nickNameModal && (
        <div
          style={{
            position: "absolute",
            borderRadius: "10px",
            padding: "20px 0px 30px 0px",
            backgroundColor: "white",
            alignItems: "center",
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            {getText(userLanguage, room, "writeName")}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px 10px",
              padding: "0px 16px",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                borderColor: "",
                borderRadius: "5px",
                border: `1px solid ${
                  userInputName === "" ? "#A2A2A2" : "#FFC028"
                }`,
              }}
            >
              <input
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  borderColor: "#FFC028",
                  border: "none",
                  width: "100%",
                }}
                value={userInputName}
                onChange={(event) => {
                  setUserInputName(event.target.value);
                }}
              />
            </div>
            {userInputName !== "" ? (
              <div
                onClick={() => {
                  const newUuid = uuidv4();
                  const location = searchParams.get("location");
                  let newLocation = getLocation();

                  localStorage.setItem(
                    "userInfo",
                    JSON.stringify({
                      uuid: newUuid,
                      userName: userInputName,
                      visitTime: new Date(),
                      location: newLocation,
                    })
                  );

                  setUserName(userInputName);
                  const userInfo = getLocalStorageInfo("userInfo");

                  socket.emit(
                    "join",
                    { name: userInputName, room: newUuid, location },
                    (error) => {}
                  );
                  setName(userInputName);
                  setNickNameModal(false);
                  speechStart();
                  setTimeout(() => {
                    previousUser(userInfo);
                  }, 1000);
                }}
                style={{
                  backgroundColor: "#FFC028",
                  width: "100%",
                  padding: "10px",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                {getText(userLanguage, room, "enter")}
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#DEDEDE",
                  width: "100%",
                  padding: "10px",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                {getText(userLanguage, room, "enter")}
              </div>
            )}
            <img
              style={{
                alignItems: "center",
                backgroundSize: "cover",
              }}
              src={logo}
              height={50}
            />
          </div>
          <span
            style={{
              fontSize: 20,
              marginTop: 10,
            }}
          >
            CS Call Center
          </span>
        </div>
      )}
    </div>
  );
};

export default Chat;
